export const Font = {
  Serif: 'Lora, serif',
  SansSerif: 'DM Sans, sans-serif',
  Cursive: 'Coiny, cursive',
  SansSerif2: 'Inter, sans-serif',
  Outfit: 'Outfit, sans-serif',
  Montserrat: 'Montserrat, sans-serif',
  Karla: 'Karla, sans-serif',
  Poetsen: 'Poetsen One, sans-serif',
  Overpass: 'Overpass, sans-serif'
};

export const textStyles = {
  desktop: {
    'display-h1': `400 80px ${Font.Serif}`,
    'display-h2': `400 50px ${Font.Serif}`,
    'display-h3': `400 22px ${Font.Serif}`,
    'text-t1': `400 38px ${Font.SansSerif}`,
    'text-t2': `400 35px ${Font.SansSerif}`,
    'text-t3': `400 30px ${Font.SansSerif}`,
    'text-t4': `400 25px ${Font.SansSerif}`,
    'text-t5': `400 22px ${Font.SansSerif}`,
    'text-t6': `700 21px ${Font.SansSerif2}`,
    'text-t7': `400 17px ${Font.SansSerif2}`,
    'cursive-c1': `400 55px ${Font.Cursive}`,
    'cursive-c2': `400 72px ${Font.Cursive}`,
    outfit: `500 30px ${Font.Outfit}`,
    montserrat: `700 12px ${Font.Montserrat}`,
    'karla-t1': `500 18px ${Font.Karla}`,
    'karla-t2': `500 16px ${Font.Karla}`,
    'karla-t3': `500 14px ${Font.Karla}`,
    'karla-t4': `500 12px ${Font.Karla}`,
    'karla-h1': `700 28px ${Font.Karla}`,
    'karla-h2': `700 20px ${Font.Karla}`,
    'karla-h3': `700 18px ${Font.Karla}`,
    'karla-h4': `700 16px ${Font.Karla}`,
    poetsen: `400 70px ${Font.Poetsen}`,
    'overpass-h1': `500 19px ${Font.Overpass}`,
    'overpass-h2': `600 16px ${Font.Overpass}`
  },
  mobile: {
    'display-h1': `400 80px ${Font.Serif}`,
    'display-h2': `400 50px ${Font.Serif}`,
    'display-h3': `400 22px ${Font.Serif}`,
    'text-t1': `400 38px ${Font.SansSerif}`,
    'text-t2': `400 35px ${Font.SansSerif}`,
    'text-t3': `400 30px ${Font.SansSerif}`,
    'text-t4': `400 25px ${Font.SansSerif}`,
    'text-t5': `400 22px ${Font.SansSerif}`,
    'text-t6': `700 21px ${Font.SansSerif2}`,
    'text-t7': `400 17px ${Font.SansSerif2}`,
    'cursive-c1': `400 80px ${Font.Cursive}`,
    'cursive-c2': `400 72px ${Font.Cursive}`,
    outfit: `500 30px ${Font.Outfit}`,
    montserrat: `700 12px ${Font.Montserrat}`,
    'karla-t1': `500 18px ${Font.Karla}`,
    'karla-t2': `500 16px ${Font.Karla}`,
    'karla-t3': `500 14px ${Font.Karla}`,
    'karla-t4': `500 12px ${Font.Karla}`,
    'karla-h1': `700 28px ${Font.Karla}`,
    'karla-h2': `700 20px ${Font.Karla}`,
    'karla-h3': `700 18px ${Font.Karla}`,
    'karla-h4': `700 16px ${Font.Karla}`,
    poetsen: `400 70px ${Font.Poetsen}`,
    'overpass-h1': `500 19px ${Font.Overpass}`,
    'overpass-h2': `600 16px ${Font.Overpass}`
  }
};
