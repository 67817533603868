import React, { useEffect } from 'react';

import { Footer } from '../components/NewFooter';
import { Navbar } from '../components/NewNavbar';
import { PlayWhenVisibleVideo } from '../components/PlayWhenVisibleVideo';
import { ScrollUp } from '../components/ScrollUp';
import { ReactComponent as Arrow1 } from '../images/tabi-case/arrow1.svg';
import { ReactComponent as Arrow2 } from '../images/tabi-case/arrow2.svg';
import { ReactComponent as Arrow3 } from '../images/tabi-case/arrow3.svg';
import { ReactComponent as Arrow4 } from '../images/tabi-case/arrow4.svg';
import Board from '../images/tabi-case/board.png';
import Computer from '../images/tabi-case/computer.png';
import Iterating1a from '../images/tabi-case/iterating1a.png';
import Iterating1b from '../images/tabi-case/iterating1b.png';
import Iterating2a from '../images/tabi-case/iterating2a.png';
import Iterating3a from '../images/tabi-case/iterating3a.png';
import Nowy from '../images/tabi-case/nowy.png';
import Pie1 from '../images/tabi-case/pie1.png';
import Pie2 from '../images/tabi-case/pie2.png';
import Solution2b from '../images/tabi-case/solution2b.png';
import Survey1 from '../images/tabi-case/survey1.png';
import Survey2 from '../images/tabi-case/survey2.png';
import Survey3 from '../images/tabi-case/survey3.png';
import Survey4 from '../images/tabi-case/survey4.png';
import Tripit from '../images/tabi-case/tripit.png';
import Tripscout from '../images/tabi-case/tripscout.png';
import Wanderlog from '../images/tabi-case/wanderlog.png';
import { Column, Icons, Row, Text } from '../ui';

import * as Styled from './Tabi.styles';

export const Tabi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar fixed />
      <Styled.Header>
        <Row align="flex-start">
          <Styled.HeaderText align="flex-end">
            <Text type="poetsen">Tabi</Text>
            <Text type="overpass-h1">Travel planner</Text>

            <Text type="overpass-h2">
              旅 (たび) ·&nbsp;&nbsp;<i>noun</i>
            </Text>
            <Text type="overpass-h2" weight={400}>
              travel; trip; journey
            </Text>
          </Styled.HeaderText>
          <Styled.Computer src={Computer} />
        </Row>
      </Styled.Header>
      <Styled.Content>
        <Styled.IntroSection>
          <div>
            <Text type="karla-h2">My Role</Text>
            <Text type="karla-t2">Solo project</Text>
          </div>
          <div>
            <Text type="karla-h2">Timeline</Text>
            <Text type="karla-t2">January 2024</Text>
          </div>
        </Styled.IntroSection>
        <Styled.SectionDivider>PROBLEM</Styled.SectionDivider>
        <Styled.SectionTitle>
          Users face multiple logistical challenges when planning for a trip,
          which can make the planning process overwhelming.
        </Styled.SectionTitle>
        <Text type="karla-t2">
          Inspired by a recent trip to Japan and massively cluttered Google Docs
          pages, Tabi was born out of a need to simplify the exciting but often
          cumbersome travel planning process. Managing the logistics of a trip
          often requires utilizing multiple sites and applications such as
          travel blogs, hotel websites, flight finders, and note-taking apps.
          Coordinating amongst group members becomes another issue as people
          have different interests and lifestyles. At the end of the day,
          travelers want to maximize their time and minimize stress—during the
          planning process and the trip itself—as much as possible.
        </Text>
        <Styled.ProblemQuestion type="karla-h3" color="teal">
          How might we streamline the travel planning process to reduce the
          number of sites needed, increase flexibility, and allow for seamless
          collaboration?
          <div>?</div>
        </Styled.ProblemQuestion>
        <Styled.SectionDivider>SOLUTION</Styled.SectionDivider>
        <Styled.SectionTitle>
          A comprehensive, AI-assisted travel itinerary builder for researching,
          planning, and collaborating.
        </Styled.SectionTitle>
        <Text type="karla-t2">
          Tabi is a user-friendly travel planner that allows users to search for
          flights/lodging/activities/restaurants/etc., flexibly plan and adjust
          itineraries, and easily share and collaborate with others.
        </Text>
        <Styled.Steps>
          <Styled.Step>
            <Styled.StepText maxWidth={298}>
              <Text type="karla-h1">Research</Text>
              <Text type="karla-t2">
                <ul>
                  <li>
                    Search for lodging, transportation, things to do, and
                    restaurants all within the search functionality
                  </li>
                  <li>
                    Use TabiAI chat box to ask travel-related questions and get
                    recommendations
                  </li>
                </ul>
              </Text>
              <div className="bg-number">1</div>
            </Styled.StepText>
            <PlayWhenVisibleVideo
              width="465"
              src="videos/tabi-case/solution1.mov"
            />
          </Styled.Step>
          <Styled.Step>
            <Styled.Step2Media>
              <PlayWhenVisibleVideo
                width="465"
                src="videos/tabi-case/solution2a.mov"
              />
              <img src={Solution2b} width={184} />
            </Styled.Step2Media>
            <Styled.StepText maxWidth={317}>
              <Text type="karla-h1">Plan & Collaborate</Text>
              <Text type="karla-t2">
                <ul>
                  <li>Side-by-side daily itinerary view</li>
                  <li>
                    Drag and drop activities into itinerary from search, lists,
                    and TabiAI chat box
                  </li>
                  <li>
                    Create lists to keep track of transit/lodging and bookmarked
                    activities
                  </li>
                  <li>
                    Get quickest transportation method between itinerary tiles
                  </li>
                  <li>Share and edit itinerary with others</li>
                </ul>
              </Text>
              <div className="bg-number">2</div>
            </Styled.StepText>
          </Styled.Step>
          <Styled.Step>
            <Styled.StepText maxWidth={320}>
              <Text type="karla-h1">Additional Features</Text>
              <Text type="karla-t2">
                <ul>
                  <li>
                    Use AI to build an entire itinerary based on inputted
                    transit and lodging info
                  </li>
                  <li>
                    Add personal notes and upload media to tiles to document
                    your travels
                  </li>
                  <li>View each day&apos;s plan and travel route on the map</li>
                </ul>
              </Text>
              <div className="bg-number">3</div>
            </Styled.StepText>
            <Styled.Step3Media>
              <PlayWhenVisibleVideo
                width="360"
                src="videos/tabi-case/solution3a.mov"
              />
              <PlayWhenVisibleVideo
                width="360"
                src="videos/tabi-case/solution3b.mov"
              />
            </Styled.Step3Media>
          </Styled.Step>
        </Styled.Steps>
        <Styled.SectionDivider>COMPETITIVE ANALYSIS</Styled.SectionDivider>
        <Styled.SectionTitle>
          Current dedicated travel planning apps lack user-friendly interfaces
          and/or offer limited features.
        </Styled.SectionTitle>
        <Text type="karla-t2">
          After analyzing several travel planning apps, two things stood out:
        </Text>
        <Styled.OutputConcerns>
          <li>
            Too narrow in focus.{' '}
            <span>
              Some apps emphasized just one particular aspect of the travel
              planning process, such as transportation or finding unique,
              personalized activities, rather than providing comprehensive tools
              that address all key aspects of building an itinerary.
            </span>
          </li>
          <li>
            Interfaces aren&apos;t the most intuitive.{' '}
            <span>
              The itinerary feature of the apps in particular lacked
              flexibility; they were not easily editable to account for
              last-minute changes and shifting plans between trip dates.
            </span>
          </li>
        </Styled.OutputConcerns>
        <Styled.Competitors align="flex-start">
          <Column>
            <Styled.CompetitorLogo src={Tripit} borderRadius={24} />
            <Text type="karla-h4">Tripit</Text>
            <Text type="karla-t5">
              Itinerary planner with focus on transportation
            </Text>
          </Column>
          <Column>
            <Styled.CompetitorLogo src={Wanderlog} />
            <Text type="karla-h4">Wanderlog</Text>
            <Text type="karla-t5">Comprehensive travel planner</Text>
          </Column>
          <Column>
            <Styled.CompetitorLogo src={Tripscout} />
            <Text type="karla-h4">Trip Scout</Text>
            <Text type="karla-t5">
              Personalized travel planner focused on activities
            </Text>
          </Column>
          <Column>
            <Styled.CompetitorLogo src={Nowy} borderRadius={100} />
            <Text type="karla-h4">Nowy</Text>
            <Text type="karla-t5">AI trip planner</Text>
          </Column>
        </Styled.Competitors>

        <Styled.SectionDivider>USER INTERVIEWS & SURVEYS</Styled.SectionDivider>
        <Styled.SectionTitle>
          Identifying market gaps and the potential for AI to assist travel
          planning.
        </Styled.SectionTitle>
        <Text type="karla-t2">
          I interviewed 6 individuals and sent out a Google survey to better
          understand what tools my users find helpful for travel planning and
          their frustrations along the way.
        </Text>

        <Styled.SurveyResults>
          <img src={Board} width={660} />
          <Styled.SurveyResult>
            <Text type="karla-t2">
              <span>6 out of 8</span> respondents find travel planning
              moderately to pretty stressful.
            </Text>
            <img src={Survey1} />
            <Arrow1
              width={182}
              style={{
                left: 134,
                top: -80
              }}
            />
            <Arrow2
              height={85}
              style={{
                right: 186,
                top: 'calc(100% + 27px)'
              }}
            />
          </Styled.SurveyResult>
          <Styled.SurveyResult>
            <img src={Survey2} />
            <Text type="karla-t2">
              Respondents lean more towards <span>spontaneous</span> trip
              planning than having a rigid plan.
            </Text>
            <Arrow3
              height={90}
              style={{
                left: 105,
                top: 'calc(100% + 32px)'
              }}
            />
          </Styled.SurveyResult>
          <Styled.SurveyResult>
            <Text type="karla-t2">
              But those who do create an itinerary typically{' '}
              <span>follow it pretty closely.</span>
            </Text>
            <img src={Survey3} />
            <Arrow4
              height={68}
              style={{
                left: 293,
                top: 156
              }}
            />
          </Styled.SurveyResult>
          <Text type="karla-h4">
            Thus,{' '}
            <span>
              <i>flexibility</i>
            </span>{' '}
            is a key priority for both spontaneous and the more serious travel
            planners out there.
          </Text>
        </Styled.SurveyResults>
        <Text type="karla-t2">
          In addition, most of the respondents have not used a dedicated travel
          planning application. Instead, they look to many different online
          sources for information and bookings (e.g. Google
          Search/Flights/Maps/Docs, Yelp, Expedia, TripAdvisor, travel blogs,
          social media).
          <br />
          <br />
          Likewise, the majority of respondents have not used AI for trip
          planning, but more than half indicated they would “very likely” use AI
          if it was a feature in a travel planning app.
        </Text>
        <Styled.FinalResults>
          <div>
            <img src={Pie1} />
            <img src={Pie2} />
          </div>
          <img src={Survey4} width={436} />
        </Styled.FinalResults>

        <Styled.SectionDivider>MAJOR INSIGHTS</Styled.SectionDivider>
        <Styled.SectionTitle>
          My users&apos; main concerns: plan flexibility, coordinating with
          others, transportation.
        </Styled.SectionTitle>
        <Text type="karla-t2">
          Three major pain points stuck out regarding the research process,
          collaborating with others, and navigating transportation. Users want
          to easily search for relevant and interesting activities, smoothly
          coordinate with group members, and maximize their time through
          efficient transit routes between destinations.
        </Text>

        <Styled.InsightsGrid>
          <Column>
            <Styled.Insight opacity={0.3}>
              <Text type="karla-h1">Research</Text>
            </Styled.Insight>
            <div>
              <Text type="karla-t3">Finding unique & memorable activities</Text>
              <Text type="karla-t3">
                Maximizing time by finding activities near each other
              </Text>
            </div>
          </Column>
          <Column>
            <Styled.Insight opacity={0.2}>
              <Text type="karla-h1">Collaboration</Text>
            </Styled.Insight>
            <div>
              <Text type="karla-t3">
                Trouble aligning interests or lack of opinions
              </Text>
              <Text type="karla-t3">
                Coordinating budget, time, and different schedules
              </Text>
              <Text type="karla-t3">
                Lack of commitment or contribution by group members
              </Text>
            </div>
          </Column>
          <Column>
            <Styled.Insight opacity={0.1}>
              <Text type="karla-h1">Transportation</Text>
            </Styled.Insight>
            <div>
              <Text type="karla-t3">
                Quickest & most cost-effective transportation method
              </Text>
              <Text type="karla-t3">Easy-to-digest travel routes</Text>
            </div>
          </Column>
        </Styled.InsightsGrid>

        <Styled.SectionDivider>TESTING + ITERATING</Styled.SectionDivider>
        <Styled.SectionTitle>
          Improving functionality, clarity, and flexibility
        </Styled.SectionTitle>
        <Text type="karla-t2">
          Several rounds of testing revealed opportunities to remove the need to
          manually track logistical details and enhance users&apos; visual
          experience so users can focus on what&apos;s important: finding
          memorable activities to build unforgettable experiences.
        </Text>

        <Styled.Steps>
          <Styled.Step>
            <Row>
              <img src={Iterating1a} height={324} />
              <Icons.rightArrow />
              <img src={Iterating1b} height={324} />
            </Row>
            <Styled.StepText maxWidth={424}>
              <Text type="karla-h1">Expand activity tiles functionality</Text>
              <Text type="karla-t2">
                <ul>
                  <li>
                    Automatically adds transit departure/arrival tiles after
                    inputting transit info
                  </li>
                  <li>
                    Automatically adds lodging check-in/check-out tiles after
                    inputting lodging info
                  </li>
                  <li>
                    Differentiate icons and background color of tiles for food
                    vs. things to do for better visual distinction
                  </li>
                </ul>
              </Text>
              <div className="bg-number smaller">1</div>
            </Styled.StepText>
          </Styled.Step>
          <Styled.Step>
            <Styled.StepText maxWidth={390}>
              <Text type="karla-h1">Enhance Lists navigation and clarity</Text>
              <Text type="karla-t2">
                <ul>
                  <li>
                    Replace Lists&apos; expand/collapse functionality with
                    horizontal scroll feature at the top
                  </li>
                  <li>
                    Add number indicator of list items on side navigation bar
                  </li>
                  <li>
                    Create lists to keep track of transit/lodging and bookmarked
                    activities
                  </li>
                  <li>
                    Add “Saved” note when bookmarking activities for greater
                    clarity
                  </li>
                </ul>
              </Text>
              <div className="bg-number smaller">2</div>
            </Styled.StepText>
            <Row>
              <img src={Iterating2a} height={186} />
              <Icons.rightArrow />
              <PlayWhenVisibleVideo
                width="263"
                src="videos/tabi-case/iterating2b.mov"
              />
            </Row>
          </Styled.Step>
          <Styled.Step>
            <Column>
              <img src={Iterating3a} width={345} />
              <Icons.downArrow />
              <PlayWhenVisibleVideo
                width="420"
                src="videos/tabi-case/iterating3b.mov"
              />
            </Column>
            <Styled.StepText maxWidth={428}>
              <Text type="karla-h1">Further optimizing for flexibility</Text>
              <Text type="karla-t2">
                <ul>
                  <li>
                    Add “Expand” and “Close window” options for Lists and
                    Itinerary
                  </li>
                  <li>
                    Allow users to not only view recommendations from AI chat
                    box but also drag and drop them into their itinerary
                  </li>
                </ul>
              </Text>
              <div className="bg-number smaller">3</div>
            </Styled.StepText>
          </Styled.Step>
        </Styled.Steps>

        <Styled.SectionDivider>FINAL PRODUCT</Styled.SectionDivider>
        <Styled.SectionTitle>The final design</Styled.SectionTitle>
        <Styled.IFrame
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FcZUpgK1D2LBoQuZoa5X0iz%2Ftravel-planner%3Fnode-id%3D390-36474%26t%3DEyIrqnHsmtdj9AA5-1%26scaling%3Dmin-zoom%26page-id%3D390%253A35992%26starting-point-node-id%3D390%253A36474"
          allowFullScreen
        />
        <Styled.SectionDivider>REFLECTIONS & NEXT STEPS</Styled.SectionDivider>
        <Styled.SectionTitle>Major takeaways</Styled.SectionTitle>
        <Text type="karla-t2">
          <Styled.NextStepsList>
            <li>
              <b>Intersection of visual design & functionality.</b> Finding the
              balance between a visually appealing yet functional experience
              required novel ways of approaching the travel planning process. In
              particular, I found the drag-and-drop feature to be the most
              practical yet fun way for users to easily edit an itinerary.
            </li>
            <li>
              <b>Balancing information presentation and cognitive load.</b>{' '}
              Planning a trip involves lots of logistical details like dates,
              times, locations, transit durations, addresses, prices, etc. Thus,
              I had to prioritize what travel information users would find most
              helpful and how best to present them to avoid visually
              overwhelming the user.
            </li>
          </Styled.NextStepsList>
        </Text>
        <Styled.NextSteps type="karla-h1">Next steps...</Styled.NextSteps>
        <Text type="karla-t2">
          <Styled.NextStepsList>
            <li>
              <b>Incorporate a budgeting feature.</b> Several interviewees
              indicated costs as an important consideration when planning a trip
              and expressed a need for coordinating budgets in larger groups
            </li>
            <li>
              <b>Greater personalization.</b> Some more personalized features to
              implement include offering unique suggestions of activities
              nearby, specific booking details within the itinerary for
              activities requiring a reservation, and a more tailored AI
              itinerary builder (e.g. users indicate their needs with a short
              quiz beforehand).
            </li>
          </Styled.NextStepsList>
        </Text>
        <Styled.SectionDivider>&nbsp;</Styled.SectionDivider>
        <Styled.ThankYou>
          <Text type="karla-h2">Thank you for reading!</Text>
          <Text type="karla-t2">
            Reach out to me at{' '}
            <a href="mailto: megyuan@ucla.edu">megyuan@ucla.edu</a> to learn
            more and connect.
          </Text>
        </Styled.ThankYou>
      </Styled.Content>
      <Footer />
      <ScrollUp />
    </>
  );
};
