import styled from 'styled-components';

import { Column, Row, Text } from '../ui';
import { Color } from '../util';

export const Header = styled(Row)`
  width: 100%;
  padding-top: 150px;
  background: linear-gradient(
    180deg,
    #177986 0%,
    #177986 20%,
    rgba(23, 121, 134, 0.25) 100%
  );

  justify-content: center;
`;

export const Computer = styled.img`
  width: 50vw;
  max-width: 800px;
  height: auto;

  margin-left: 50px;
`;

export const HeaderText = styled(Column)`
  margin-top: 100px;
  > :nth-child(2) {
    margin-top: -12px;
    margin-bottom: 56px;
  }

  ${Text} {
    color: ${({ theme }) => theme.palette.white};
  }

  i {
    font-weight: 300;
  }
`;

export const Content = styled(Column)`
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }
`;

export const IntroSection = styled(Row)`
  padding: 50px 150px 0 150px;
  margin-bottom: -20px;
  justify-content: space-between;
  align-items: flex-start;

  > * {
    max-width: 311px;
    > :first-child {
      font-weight: 800;
      margin-bottom: 10px;
    }
  }
`;

export const ProblemQuestion = styled(Text)`
  margin-top: 50px;
  padding: 0 205px;

  text-align: center;
  position: relative;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 160px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.teal};
    opacity: 0.07;
    z-index: -1;
    line-height: 160px;
  }
`;

export const SectionDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.border};
  padding: 30px 0;
  margin-top: 80px;
  font: ${({ theme }) => theme.textStyles.desktop['karla-h3']};
`;

export const SectionTitle = styled.div`
  margin-bottom: 30px;
  font: ${({ theme }) => theme.textStyles.desktop['karla-h1']};
`;

export const Solution = styled.div`
  margin: 50px auto 30px auto;
  padding: 0 85px;
  position: relative;
`;

export const Steps = styled.div`
  margin-top: ${({ marginTop = 110 }) => marginTop}px;
  > :not(:first-child) {
    margin-top: 135px;
  }
`;

export const Step = styled(Row)`
  justify-content: center;
  > :first-child {
    margin-right: ${({ gap = 55 }) => gap}px;
  }

  video,
  img {
    box-shadow: 0px 0px 9.04px 0.45px #063136cc;
    border-radius: 8px;
  }

  svg {
    fill: ${({ theme }) => theme.palette.teal};
    width: 38px;
    height: 38px;
  }
`;

export const StepText = styled.div`
  position: relative;
  max-width: ${({ maxWidth = 365 }) => maxWidth}px;

  ul {
    margin: 15px 0 15px 10px;

    > :not(:first-child) {
      margin-top: 10px;
    }
  }

  ul.no-top-margin {
    margin-top: 0 !important;
  }

  > .bg-number {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-8%, -50%);
    font-size: 540px;
    line-height: 600px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.teal};
    opacity: 0.08;
  }

  > .bg-number.smaller {
    font-size: 420px;
  }
`;

export const Step2Media = styled.div`
  width: 495px;
  height: 382px;
  position: relative;

  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  > :last-child {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const Step3Media = styled.div`
  width: 460px;
  height: 527px;
  position: relative;

  > :first-child {
    position: absolute;
    top: 0;
    right: 0;
  }

  > :last-child {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const Competitors = styled(Row)`
  margin-top: 30px;
  justify-content: center;
  > :not(:first-child) {
    margin-left: 60px;
  }

  > ${Column} {
    max-width: 140px;

    > ${Text} {
      text-align: center;
    }

    > ${Text}:last-child {
      margin-top: 6px;
    }
  }
`;

export const CompetitorLogo = styled.img`
  width: 109px;
  height: 109px;
  border-radius: ${({ borderRadius }) => borderRadius || 18}px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 12px;
`;

export const OutputScreens = styled.img`
  width: 1027px;
  margin: 32px 0 54px 0;
`;

export const OutputConcerns = styled.ol`
  margin-top: 8px;
  margin-left: 10px;
  font-weight: bold;

  > :not(:first-child) {
    margin-top: 10px;
  }

  span {
    font-weight: normal;
  }
`;

export const SurveyResults = styled(Column)`
  > :first-child {
    margin-top: 30px;
    margin-bottom: 108px;
  }

  > :nth-child(2) {
    margin-top: 0 !important;
  }

  > :not(:first-child) {
    margin-top: 80px;
  }

  > :last-child {
    margin-top: 67px !important;
    margin-bottom: 66px;
    width: 550px;
    text-align: center;
  }

  img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #17798640;
  }

  span {
    font-weight: 800;
    color: ${({ theme }) => theme.palette.teal};
  }

  svg {
    position: absolute;
  }
`;

export const SurveyResult = styled.div`
  position: relative;
  justify-content: center;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  column-gap: 75px;

  > img {
    width: 420px;
  }

  > ${Text} {
    width: 302px;
  }
`;

export const FinalResults = styled.div`
  width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 436px;
  column-gap: 20px;
  align-items: center;

  > :first-child {
    height: calc(100% + 20px);
    position: relative;

    > * {
      position: absolute;
      height: 132px;
    }

    > :first-child {
      top: 0;
      left: 0;
    }

    > :last-child {
      bottom: 0;
      right: 0;
    }
  }

  img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #17798640;
  }
`;

export const InsightsGrid = styled.div`
  margin-top: 50px;
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto auto;

  border: 2.5px solid
    ${({ theme }) => Color.makeHexTranslucent(theme.palette.teal, 0.5)};
  border-radius: 12px;

  > :not(:last-child) {
    border-right: 2.5px solid
      ${({ theme }) => Color.makeHexTranslucent(theme.palette.teal, 0.5)};
  }

  > * {
    padding: 20px 24px;

    > :last-child {
      max-width: 165px;
      > :not(:first-child) {
        margin-top: 24px;
      }

      ${Text} {
        text-align: center;
      }
    }
  }
`;

export const Insight = styled.div`
  padding: 10px 24px;

  ${Text} {
    font-size: 22px;
    font-weight: 800;
  }

  border-radius: 100px;
  background-color: ${({ theme, opacity }) =>
    Color.makeHexTranslucent(theme.palette.teal, opacity)};

  margin-bottom: 14px;
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 575px;
  margin-top: 50px;
  border: 1px solid ${({ theme }) => theme.palette.border};
`;

export const NextSteps = styled(Text)`
  margin: 30px 0;
`;

export const NextStepsList = styled.ol`
  margin-top: 8px;
  margin-left: 10px;

  > :not(:first-child) {
    margin-top: 4px;
  }

  > li::marker {
    font-weight: bold;
  }
`;

export const ThankYou = styled.div`
  > :first-child {
    margin-bottom: 38px;
  }
  margin-bottom: 160px;

  a {
    font-weight: 800;
  }
`;
